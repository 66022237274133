import { BaseApi } from '../BaseApi'
import { MeResult } from './types'

export class MeApi {
  public static async loadMe() {
    return BaseApi.authenticatedCall()
      .url(`v1/mobile/me`)
      .get()
      .json(json => json) as Promise<MeResult>
  }
}
