import { Reducer } from 'redux'
import { SurveysActionTypes, SurveysState } from './types'
import { ActionSurveysObjectTypes } from './action'

const initialSurveysState: SurveysState = {
  pages: {},
}

export const surveysReducer: Reducer<SurveysState, ActionSurveysObjectTypes> = (state = initialSurveysState, action) => {
  switch (action.type) {
    case SurveysActionTypes.STORE_SURVEYS:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.idPage]: {
            last: action.payload.result.last,
            page: action.payload.result.page,
            size: action.payload.result.size,
            totalElements: action.payload.result.totalElements,
            totalPages: action.payload.result.totalPages,
            content: [...(state.pages[action.payload.idPage] ? state.pages[action.payload.idPage].content : []), ...action.payload.result.content],
          },
        },
      }
    case SurveysActionTypes.STORE_CLEAR_SURVEYS:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.idPage]: action.payload.result,
        },
      }
    default:
      return state
  }
}
