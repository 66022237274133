export default {
  home: {
    loginButton: 'Aller sur la page de login',
    title: 'Accueil',
  },
  sidebar: {
    version: 'Version',
  },
  login: {
    userName: 'Utilisateur',
    password: 'Mot de passe',
    homeButton: 'Aller sur la home page',
    title: 'Page de login',
    redux: 'Redux test',
    loader: 'Toggle selector',
  },
  zones: {
    consult: 'Consulter',
  },
  notifications: {
    notifications: 'Notifications',
    messageEmpty: `Vous n'avez pas de notifications`,
    settingTitle: 'Personnaliser le service',
    categorieAll: 'Recevoir les notifications',
    enabledNotif: 'Activer les notifications',
    disabledNotif: 'Désactiver les notifications',
    information:
      "Les notifications vous alertent en cas d'urgence ou d'évènement se déroulant dans votre commune. Vous pouvez personnaliser ce service en sélectionnant les catégories ci-dessous",
    permissionDenied: 'Notification désactivée',
    phonePermission: "Dans les réglages du téléphone, appuyez sur l'application et activez les notifications si vous souhaitez en bénéficier",
    moreInfo: 'En savoir plus',
  },
  date: {
    LeDDMMYYYatHHMM: "'Le' dd/MM/yyyy 'à' HH'h'mm",
    LeDDMMYYY: "'Le' dd/MM/yyyy",
    DurationYears: "'il y a 'y' ans'",
    DurationMonths: "'il y a 'M' mois'",
    DurationDays: "'il y a 'd' jours'",
    DurationHHMM: "'il y a 'h' heures et 'm' minutes'",
    DurationHH: "'il y a 'h' heures'",
    DurationMMSS: "'il y a 'm' minutes et 's' secondes'",
    DurationMM: "'il y a 'm' minutes'",
    DurationSS: "'il y a 's' secondes'",
    now: "à l'instant",
    updated: 'Mis à jour ',
    fromDDMMYYYY: "'du' dd/MM/yyyy",
    toDDMMYYYY: "'au' dd/MM/yyyy",
  },
  toast: {
    error: 'Oups ! Une erreur est apparue !\nMerci de réessayer :)',
    thankyou: 'Merci',
    closed: 'Fermer',
    success: "L'opération s'est correctement executée",
  },
  gridActu: {
    lastActu: 'Dernières actualités',
  },
  cartography: {
    go: 'y aller',
  },
  favorites: {
    title: 'Vos favoris',
    add: 'Ajouter un service',
    titleSelect: 'Selectionner un service',
    noServices: 'Aucun service trouvé',
    multiChoice: {
      add: "Ajouter à l'écran d'accueil",
    },
  },
  form: {
    noForm: "Vous n'êtes pas connecté à internet. Vérifier votre connexion et recommencer",
    zipCode: 'Code postal',
    city: 'Ville',
    success: 'Votre demande à été prise en compte !',
    selectItem: 'Sélectionner un élément',
  },
  validation: {
    error: 'Il y a des erreurs dans votre formulaire, veuillez les corriger',
    required: 'Ce champ est obligatoire',
    email: `L'adresse email est invalide`,
    max: 'Le nombre de caractère est trop grand ({{count}})',
    rgpd: 'Veuillez cocher la case RGPD pour envoyer le formulaire',
  },
  referentiel: {
    search: 'Filtrer les annuaires',
    apply: 'Filtrer',
    closed: 'fermé',
    thisWeek: 'Fréquentation cette semaine',
    today: "Fréquentation aujourd'hui",
  },
  signalement: {
    next: 'Continuer',
    title: 'Signalements',
    send: 'Transmettre',
    state: 'Etat du signalement',
    comment: 'Votre commentaire',
    comments: 'Commentaires',
    yourReport: 'Votre signalement',
    process: 'Traiter',
  },
  imageUpload: {
    title: 'Sélectionner une image',
    cancel: 'Annuler',
    takePhoto: 'Prendre une photo',
    chooseFromLibrary: 'Sélectionnez une photo',
    error: "Cette photo n'est pas valide",
  },
  add: 'Ajouter',
  noData: 'Aucune donnée à afficher',
  more: 'Plus',
  call: 'Appeler',
  phone: 'Tel : {{phone}}',
  gpsCard: {
    gps: 'GPS :',
    errorMessage: "Vous n'avez pas autorisé le partage de votre position.",
    errorLocation: "Oups... nous n'avons pas réussi à récupérer votre position.",
    yourPosition: 'Votre position :',
  },
  website: 'Site web',
  days: {
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
  },
  daysShort: {
    sunday: 'Di',
    monday: 'Lu',
    tuesday: 'Ma',
    wednesday: 'Me',
    thursday: 'Je',
    friday: 'Ve',
    saturday: 'Sa',
  },
  legend: 'Légende',
  back: 'Retour',
  view: 'Consulter',
  tideTime: {
    now: 'Maintenant:',
    theoretical: 'Données issues du SHOM.',
  },
  offline: {
    title: 'Oups',
    line1: 'Vous semblez être hors-ligne',
    line2: `S'il vous plait, veuillez vérifier votre connexion internet.`,
    retry: 'Réessayer',
  },
  state: 'Etat',
  agenda: {
    at: 'au',
  },
  movie: {
    now: `A l'affiche`,
    coming: `Prochainement`,
    todayAt: `Aujourd'hui à`,
    next: 'Prochaine séance',
    duration: 'Durée',
    from: 'De',
    with: 'Avec',
    genre: 'Genre',
    subtitles: 'Sous-titré',
  },
  authorization: {
    letPhoneAccess: `Dans les réglages du téléphone, appuyez sur l'application et activez les autorisations`,
  },
  promotion: {
    continue: 'Continuer',
    start: 'Je commence',
  },
  grcYpok: {
    login: 'Se connecter',
    open: 'Ouvrir',
    accessAnonymous: 'Accéder sans authentification',
    serviceBeingProcessed: 'Service en cours de maintenance',
    tryLater: 'Accès au service indisponible. Veuillez-ressayez ultérieurement',
    yourLoggedAs: 'Vous êtes connecté en tant que',
    forgot: 'Mot de passe oublié ?',
  },
  permission: {
    ok: 'Accepter',
    cancel: 'Refuser',
    titleFineLocation: 'Accéder à votre localisation',
    messageFineLocation:
      "Ceci nous permet d'utiliser votre emplacement pour vous fournir certaines fonctions comme votre position sur une carte de la ville, faire une demande géolocalisé (travaux voirie, déchets, etc...).",
    messageCameraAccess: 'Ceci nous permet de prendre une photo pour certains fonctions, comme faire une demande ou remonter un signalement',
    askMeLater: 'Plus tard',
  },
  notAvailable: 'non disponible',
  addCity: {
    title: 'Ajouter une ville',
    searchTitle: 'Rechercher un ville',
    searchPlaceholder: 'Ville, code postal ...',
    successAdd: 'La ville a bien été rajouté à vos favoris',
    citySelected: 'Vous avez déjà la ville %{name} en favoris',
  },
  myCitys: {
    title: 'Mes villes',
    emptySelectCity: "Vous n'avez pas de ville pour l'instant",
    removeTitle: 'Suppression',
    removeMessage: 'Voulez-vous supprimer %{name} de vos favoris ?',
  },
  confirm: {
    yes: 'Oui',
    no: 'Non',
  },
  airQuality: {
    title: 'Qualité Air',
    good: 'Bon',
    way: 'Moyen',
    bad: 'Dégradé',
    poor: 'Mauvais',
  },
  action: {
    add: 'Ajouter',
    cancel: 'Annuler',
    update: 'Mettre à jour',
  },
  serviceUnavailable: 'Service indisponible, nous vous invitons à réessayer ultérieurement',
  weather: {
    title: 'Météo',
  },
  updatedTime: 'Il y a : ',
  signIn: {
    welcome: 'Bienvenue !',
    subtitle: 'Connectez-vous pour continuer',
    email: {
      label: 'Adresse e-mail',
      placeholder: 'Ex. : robert.dupont@gmail.com',
    },
    password: {
      label: 'Mot de passe',
      placeholder: 'Entrez votre mot de passe',
    },
    forgetPassword: 'Mot de passe oublié ?',
    connectWithEmail: 'Connexion avec mon E-mail',
    continueAsGuest: "Continuer en tant qu'invité",
    or: 'Ou',
    connectWithGoogle: 'Connexion avec Google',
    connectWithFacebook: 'Connexion avec Facebook',
    connectWithApple: 'Connexion avec Apple',
    noAccountYet: 'Pas encore de compte ?',
    createOne: 'Créez en un !',
    wrongCredentials: "Impossible de se connecter.\nL'email et le mot de passe ne correspondent pas",
    accountExistWithExistingCredential: 'Le compte existe déjà. Merci de réessayer avec une autre méthode de connexion',
  },
  signOut: {
    disconnecing: 'Déconnexion',
  },
  signUp: {
    welcome: 'Créez votre compte !',
    subtitle: 'Connectez-vous ou inscrivez-vous pour continuer',
    email: {
      label: 'Adresse e-mail',
      placeholder: 'Ex. : robert.dupont@gmail.com',
    },
    password: {
      label: 'Mot de passe',
      placeholder: 'Entrez votre mot de passe',
    },
    connectWithEmail: 'Créer mon compte',
    alreadyHaveAccount: 'Vous avez déjà un compte ?',
    connect: 'Connectez-vous',
    acceptCgu: {
      part1: "J'accepte les",
      part2: "conditions générales d'utilisation",
      part3: "de l'application",
    },
    error: {
      emailAlreadyUsed: 'Erreur: Email déjà utilisé',
    },
  },
  myAccount: {
    title: 'Mon compte',
    parameters: {
      title: 'Paramètres de mon compte',
      logout: 'Me deconnecter',
      personalInfo: 'Informations personnelles',
      deleteAccount: 'Supprimer mon compte',
      changePassword: 'Changer mon mot de passe',
      deleteAccountModal: {
        title: 'Suppression du compte',
        description: 'Voulez-vous vraiment supprimer votre compte ?',
        confirm: 'Confirmer',
        cancel: 'Annuler',
      },
    },
    myAccountDeletion: {
      subtitle: 'Mon compte',
      title: 'Supprimer mon compte',
      parameters: {
        title: 'Important',
        information: 'Cette section permet de supprimer votre compte.',
        warning: 'Si vous allez au bout du processus, vous perdrez toutes vos données. Vos signalements seront anonymisées.',
      },
    },
    myAccountConfirmDeletion: {
      parameters: {
        title: 'Vous êtes sur le point de supprimer votre compte',
        question: 'Etes-vous certain de vouloir continuer ?',
        firstInfo: 'Vous n’accéderez plus à vos signalements',
        secondInfo: 'Vos signalements et données associées seront anonymisées',
      },
      confirm: 'Saisissez “SUPPRIMER” dans la zone ci-dessous',
      keyword: 'SUPPRIMER',
      alert: 'La suppression a échoué',
    },
    myAccountWasDeleted: {
      parameters: {
        title: 'Votre compte a été supprimé',
        informations: 'Vous pouvez toujours naviguer dans l’application en tant qu’invité ou vous recréer un compte.',
        message: 'Nous espérons vous revoir bientôt.',
      },
      confirm: 'Retourner à la page d’accueil',
    },
    preferences: {
      title: 'Préférences',
      notifications: 'Notifications',
    },
  },
  resetPassword: {
    title: 'Réinitialisez votre mot de passe',
    email: {
      label: 'Adresse e-mail',
      connect: 'Me connecter',
    },
    password: {
      label: 'Nouveau mot de passe',
      placeholder: '••••••••',
    },
    confirmPassword: {
      label: 'Confirmer le nouveau mot de passe',
      placeholder: '••••••••',
    },
    connect: 'Me connecter',
  },
  resetPasswordSendEmail: {
    title: 'Réinitialisation de mot de passe',
    subtitle: "Saisissez l'adresse e-mail associée à votre compte. Nous vous enverrons un lien par e-mail pour le réinitialiser.",
    email: {
      label: 'Adresse e-mail',
      placeholder: 'Ex. : robert.dupont@gmail.com',
    },
    sendResetLink: 'Recevoir le lien de réinitialisation',
  },
  resetPasswordVerification: {
    title: 'Vous y êtes presque',
    subtitle: 'Consultez votre email afin de récupérer le code de réinitialisation de votre mot de passe à saisir ci-dessous',
    sendResetLink: 'Réinitialiser mon mot de passe',
    alreadyRegistered: 'Vous avez déjà un compte ?',
    connect: 'Connectez-vous',
    resetPassword: 'Réinitialiser mon mot de passe',
  },
  personalInfo: {
    title: 'Informations personnelles',
    firstName: {
      label: 'Prénom',
      placeholder: 'Ex : Mathieu',
    },
    lastName: {
      label: 'Nom',
      placeholder: 'Ex : Lessitoyen',
    },
  },
  changePassword: {
    title: 'Changer mon mot de passe',
    oldPassword: {
      label: 'Ancien mot de passe',
    },
    newPassword: {
      label: 'Nouveau mot de passe',
    },
    confirmNewPassword: {
      label: 'Confirmer le nouveau mot de passe',
    },
    confirmChangePassword: 'Mettre à jour mon mot de passe',
    successMessage: 'Le mot de passe a été correctement modifié',
    warning: {
      passwordNotMatching: {
        title: 'Attention',
        content: 'Le mot de passe et sa confirmation ne correspondent pas.\nMerci de corriger votre saisie.',
      },
      passwordNotValid: {
        title: 'Important',
        content: 'Votre mot de passe doit respecter les règles suivantes :\n- Avoir au moins 8 caractères\n- Comporter une lettre\n- Comporter un chiffre',
      },
    },
  },
  emailVerificationSent: {
    title: 'Vous y êtes presque',
    subtitle: 'Vous avez reçu un email pour activer votre compte',
    description: "L'email a été envoyé à l'adresse suivante :",
    back: 'Retour',
  },
  emailVerificationDone: {
    title: 'Félicitations !',
    subtitle: "Votre compte est activé et vous pouvez désormais bénéficier des services de l'application",
    continue: "OK, c'est parti !",
  },
  taskReport: {
    defaultPageTitle: 'Tâche à traiter',
    errorTitle: 'Mes Tâches',
    noGRCAccountTitle: 'Aucun compte GRC 360 associé',
    noGRCAccountExplain: 'Afin de traiter vos tâches sur l’application mobile, vous devez disposer d’un compte GRC 360 rattaché à votre adresse email',
    noGRCAccountFooter: 'Veuillez contacter votre responsable pour en savoir plus.',
    commonErrorTitle: 'Impossible d’afficher la page demandée',
    commonErrorExplain: 'Vérifiez que vous êtes bien connecté au réseau et actualiser la page.',
    commonErrorFooter: 'Si le problème persiste, contactez votre administrateur.',
    refreshPage: 'Actualiser la page',
  },
  CGU: {
    noCGU: 'Aucune CGU disponible',
  },
  tide: {
    title: 'Horaires des marées',
    unit: 'm',
    coeffAbbreviation: 'Coef.',
    today: `Aujourd'hui`,
  },
  surveys: {
    fallbackTitle: 'Sondage',
    status: {
      toAnswer: 'Participer maintenant',
      answered: 'Vous avez déjà répondu à ce sondage',
      expired: 'Ce sondage est terminé',
    },
  },
}
