import { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent } from 'react'
import { StyleSheet } from 'react-native'
import { WebView } from 'react-native-webview'
import { Spinner } from 'native-base'
import { Content } from '../../components/NativeBaseLegacy'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'

const baseSurveyUrl = 'https://sondage.dev.tools.citopia.fr/survey'

export const SurveyPageForm: FunctionComponent<StackScreenProps<ActionViewParamsType, 'SurveyPageForm'>> = ({ route }) => {
  const { uuid, title, useruuid } = route.params

  return (
    <Page left={<LeftButtonBack />} title={title || i18n.t('surveys.fallbackTitle')}>
      <Content style={styles.container}>
        <WebView
          source={{
            uri: `${baseSurveyUrl}/${uuid}?useruuid=${useruuid}`,
          }}
          javaScriptEnabled
          renderLoading={() => <Spinner />}
        />
      </Content>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
