import { Reducer } from 'redux'
import { ActionMeObjectTypes } from './actions'
import { MeActionTypes, MeState } from './types'

const initialMeState: MeState = {
  me: {
    useruuid: '',
  },
}

export const meReducer: Reducer<MeState, ActionMeObjectTypes> = (state = initialMeState, action) => {
  switch (action.type) {
    case MeActionTypes.STORE_ME:
      return {
        ...state,
        me: action.payload.me,
      }
    default:
      return state
  }
}
