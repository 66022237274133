import { ActionsUnion, createAction } from '@martin_hotell/rex-tils'
import { SurveysResult } from '../../api/Surveys/types'
import { SurveysActionTypes } from './types'

export const SurveysActions = {
  loadSurveys: (clear: boolean, idPage: number) => createAction(SurveysActionTypes.LOAD_SURVEYS, { clear, idPage }),
  storeSurveys: (result: SurveysResult, idPage: number) => createAction(SurveysActionTypes.STORE_SURVEYS, { result, idPage }),
  storeClearSurveys: (result: SurveysResult, idPage: number) => createAction(SurveysActionTypes.STORE_CLEAR_SURVEYS, { result, idPage }),
  refreshSurveys: (idPage: number) => createAction(SurveysActionTypes.REFRESH_SURVEYS, { idPage }),
}

export type ActionSurveysObjectTypes = ActionsUnion<typeof SurveysActions>
