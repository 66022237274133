import { Action, combineReducers } from 'redux'
import { urgentNumbersReducer } from './UrgentNumbers/reducer'
import { UrgentNumbersState } from './UrgentNumbers/types'
import { weeklySchedulesReducer } from './WeeklySchedules/reducers'
import { WeeklySchedulesState } from './WeeklySchedules/types'
import { actualitesReducer, newsReducer } from './actualites/reducers'
import { ActualitesState, NewsState } from './actualites/types'
import { airQualityReducer } from './airQuality/reducer'
import { AirQualityState } from './airQuality/types'
import { authenticationReducer } from './authentication/reducer'
import { AuthenticationState } from './authentication/types'
import { cantineReducer } from './cantine/reducer'
import { CantineState } from './cantine/types'
import { CartographyReducer } from './cartography/reducer'
import { CartographyState } from './cartography/types'
import { cartographyBeaconsReducer } from './cartographyBeacons/reducer'
import { CartographyBeaconsState } from './cartographyBeacons/types'
import { cartographyRealTimeMapReducer } from './cartographyRealTime/reducer'
import { RealTimeMapState } from './cartographyRealTime/types'
import { cartographyZonesReducer } from './cartographyZones/reducer'
import { CartographyZonesState } from './cartographyZones/types'
import { cityReducer } from './city/reducers'
import { CityState } from './city/types'
import { externalLinksReducer } from './externalLinks/reducers'
import { ExternalLinksState } from './externalLinks/types'
import { favoritesCatalogReducer } from './favoritesCatalog/reducers'
import { FavoritesCatalogState } from './favoritesCatalog/types'
import { favoritesConfigReducer } from './favoritesConfig/reducers'
import { FavoritesConfigState } from './favoritesConfig/types'
import { favoritesUserReducer } from './favoritesUser/reducers'
import { FavoritesUserState } from './favoritesUser/types'
import { GrcYpokReducer } from './grcYpok/reducer'
import { GrcYpokState } from './grcYpok/types'
import { gridPageReducer } from './gridPage/reducer'
import { GridPageState } from './gridPage/types'
import { HomeReducer } from './home/reducer'
import { HomeState } from './home/types'
import { intermediatePageReducer } from './intermediatePage/reducers'
import { IntermediatePageState } from './intermediatePage/types'
import { loadingReducer } from './loading/reducer'
import { LoadingState } from './loading/types'
import { MenuReducer } from './menu/reducer'
import { MenuState } from './menu/types'
import { movieReducer } from './movies/reducers'
import { MovieState } from './movies/types'
import { notificationReducer } from './notification/reducer'
import { NotificationsState } from './notification/types'
import { referentielsReducer } from './referentiels/reducers'
import { ReferentielsState } from './referentiels/types'
import { signalementsReducer } from './signalements/reducer'
import { SignalementsState } from './signalements/types'
import { taskReportReducer } from './taskReport/reducer'
import { TaskReportState } from './taskReport/types'
import { themeReducer } from './theme/reducer'
import { ThemeState } from './theme/types'
import { TideReducer } from './tide/reducer'
import { TideState } from './tide/types'
import { weatherReducer } from './weather/reducer'
import { WeatherState } from './weather/types'
import { SurveysState } from './surveys/types'
import { surveysReducer } from './surveys/reducer'
import { meReducer } from './me/reducer'
import { MeState } from './me/types'

const appReducer = combineReducers({
  authentication: authenticationReducer,
  home: HomeReducer,
  gridPageConfig: gridPageReducer,
  notifications: notificationReducer,
  theme: themeReducer,
  menu: MenuReducer,
  loading: loadingReducer,
  cartography: CartographyReducer,
  cartographyRealTimeMap: cartographyRealTimeMapReducer,
  intermediatePage: intermediatePageReducer,
  favoritesCatalog: favoritesCatalogReducer,
  actualites: actualitesReducer,
  news: newsReducer,
  referentiels: referentielsReducer,
  weeklySchedules: weeklySchedulesReducer,
  urgentNumbers: urgentNumbersReducer,
  signalements: signalementsReducer,
  cartographyZones: cartographyZonesReducer,
  cartographyBeacons: cartographyBeaconsReducer,
  cantine: cantineReducer,
  movie: movieReducer,
  grcYpok: GrcYpokReducer,
  city: cityReducer,
  airQuality: airQualityReducer,
  weather: weatherReducer,
  externalLinks: externalLinksReducer,
  reportProcess: taskReportReducer,
  favoritesUser: favoritesUserReducer,
  favoritesConfig: favoritesConfigReducer,
  tide: TideReducer,
  surveys: surveysReducer,
  me: meReducer,
})

export interface RootState {
  authentication: AuthenticationState
  home: HomeState
  gridPageConfig: GridPageState
  notifications: NotificationsState
  theme: ThemeState
  menu: MenuState
  loading: LoadingState
  cartography: CartographyState
  intermediatePage: IntermediatePageState
  favoritesCatalog: FavoritesCatalogState
  actualites: ActualitesState
  news: NewsState
  referentiels: ReferentielsState
  weeklySchedules: WeeklySchedulesState
  urgentNumbers: UrgentNumbersState
  signalements: SignalementsState
  cartographyZones: CartographyZonesState
  cartographyBeacons: CartographyBeaconsState
  cartographyRealTimeMap: RealTimeMapState
  cantine: CantineState
  movie: MovieState
  grcYpok: GrcYpokState
  city: CityState
  airQuality: AirQualityState
  weather: WeatherState
  externalLinks: ExternalLinksState
  reportProcess: TaskReportState
  favoritesUser: FavoritesUserState
  favoritesConfig: FavoritesConfigState
  tide: TideState
  surveys: SurveysState
  me: MeState
}

type RootReducer = ReturnType<typeof appReducer>

export const rootReducer = (state: RootState | undefined, action: Action): RootReducer => appReducer(state, action)
