import { BaseApi } from '../BaseApi'
import { ActualitesResult, GridAirQualityConfig, GridPageResult, GridTideConfig, IntermediatePageResult, RssFeedConfigResult, WeatherResult } from './types'

export class GridPageApi {
  public static async load(width: number, id?: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/gridpage/load')
      .query({ width: Math.trunc(width), id: id || '' })
      .get()
      .json(json => json) as Promise<GridPageResult>
  }

  public static async loadActualites(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/gridpage/actualites')
      .query({ id })
      .get()
      .json(json => json) as Promise<ActualitesResult>
  }

  public static async loadRssFeed(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/gridpage/rss')
      .query({ id })
      .get()
      .json(json => json) as Promise<RssFeedConfigResult>
  }

  public static async loadWeather(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/meteo/load')
      .query({ id })
      .get()
      .json(json => json) as Promise<WeatherResult>
  }

  public static async loadIntermediatePage(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/gridpage/intermediatepage')
      .query({ id })
      .get()
      .json(json => json) as Promise<IntermediatePageResult>
  }

  public static async loadGridPageAirQuality(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/gridpage/airquality')
      .query({ id })
      .get()
      .json(json => json) as Promise<GridAirQualityConfig>
  }

  public static async loadGridPageTide(id: number) {
    return BaseApi.authenticatedCall()
      .url('v1/mobile/gridpage/tide-schedules')
      .query({ id })
      .get()
      .json(json => json) as Promise<GridTideConfig>
  }
}
