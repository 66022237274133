import { BaseApi } from '../BaseApi'
import { SurveysResult } from './types'

export class SurveysApi {
  public static async loadSurveys(page: number, size: number) {
    return BaseApi.authenticatedCall()
      .url(`v1/vooter/survey`)
      .query({ page, size })
      .get()
      .json(json => json) as Promise<SurveysResult>
  }
}
