import { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent } from 'react'
import { LeftButtonBack, Page } from '../../components/Page'
import { ActionViewParamsType } from '../../navigation/Routes'
import { SurveyList } from '../../components/SurveyList'

export const SurveyPage: FunctionComponent<StackScreenProps<ActionViewParamsType, 'SurveyPage'>> = ({ route }) => {
  const title = route.params.titre
  const id = route.params.idParams

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <SurveyList id={id} />
    </Page>
  )
}
