export const env = {
  baseUrl: 'https://api-pp.beattractive.fr/',
  sentryDsn: 'https://c601aea1ba6144008e1e3885898f7755@o1300890.ingest.sentry.io/6549943',
  clientUuid: 'aaf17db8-9534-4dee-a4bf-5d141a898974',
  ENV: 'staging',
  featureFlags: {
    areCheatCodesEnabled: true,
    isCodePushEnabled: true,
    isCodePushManual: true,
    isFavoritesEnabled: true,
    authentication: {
      enabled: true,
      appleProviderEnabled: true,
      facebookProviderEnabled: true,
      googleProviderEnabled: true,
    },
  },
  googleClientId: '655195907034-9f5en6cpbj9k4oqh3fsll4c2lr2jn35i.apps.googleusercontent.com',
  emailValidationDynamicLink: 'https://attractivecitymobile.page.link/email-confirmation',
  matomoUrlBase: 'https://analytics.tools.citopia.fr/',
  matomoSiteId: 3,
}
