export enum LoadingActionTypes {
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
}

export enum LoaderName {
  SIGN_IN_USER = 'SIGN_IN_USER',
  LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS',
  REFRESH_NOTIFICATIONS = 'REFRESH_NOTIFICATIONS',
  LOAD_NOTIFICATION_SETTINGS = 'LOAD_NOTIFICATION_SETTINGS',
  LOAD_CAROUSEL = 'LOAD_CAROUSEL',
  LOAD_GRID_PAGE = 'LOAD_GRID_PAGE',
  LOAD_GRID_ACTUALITES = 'LOAD_GRID_ACTUALITES',
  LOAD_GRID_WHEATHER = 'LOAD_GRID_WHEATHER',
  LOAD_GRID_RSS_FEED_CONFIG = 'LOAD_GRID_RSS_FEED_CONFIG',
  LOAD_FAVORITES_USER = 'LOAD_FAVORITES_USER',
  ADD_FAVORITES_USER = 'ADD_FAVORITES_USER',
  ADD_MULTIPLE_FAVORITES_USER = 'ADD_MULTIPLE_FAVORITES_USER',
  DELETE_FAVORITES_USER = 'DELETE_FAVORITES_USER',
  LOAD_FAVORITES_CATALOG = 'LOAD_FAVORITES_CATALOG',
  LOAD_FAVORITES_CATALOG_BY_MOST_USED = 'LOAD_FAVORITES_CATALOG_BY_MOST_USED',
  REFRESH_FAVORITES_CATALOG_BY_MOST_USED = 'REFRESH_FAVORITES_CATALOG_BY_MOST_USED',
  LOAD_FAVORITES_CATALOG_BY_THEMATIC = 'LOAD_FAVORITES_CATALOG_BY_THEMATIC',
  REFRESH_FAVORITES_CATALOG_BY_THEMATIC = 'REFRESH_FAVORITES_CATALOG_BY_THEMATIC',
  LOAD_FAVORITES_CATALOG_BY_NAME = 'LOAD_FAVORITES_CATALOG_BY_NAME',
  REFRESH_FAVORITES_CATALOG_BY_NAME = 'REFRESH_FAVORITES_CATALOG_BY_NAME',
  LOAD_FAVORITES_SUB_THEMATIC_CATALOG = 'LOAD_FAVORITES_SUB_THEMATIC_CATALOG',
  REFRESH_FAVORITES_SUB_THEMATIC_CATALOG = 'REFRESH_FAVORITES_SUB_THEMATIC_CATALOG',
  LOAD_ACTUALITE = 'LOAD_ACTUALITE',
  LOAD_NEWS = 'LOAD_NEWS',
  REFRESH_NEWS = 'REFRESH_NEWS',
  LOAD_REFERENTIEL = 'LOAD_REFERENTIEL',
  LOAD_REFERENTIEL_LIST = 'LOAD_REFERENTIEL_LIST',
  REFRESH_REFERENTIEL_LIST = 'REFRESH_REFERENTIEL_LIST',
  LOAD_WEEKLY_SCHEDULES = 'LOAD_WEEKLY_SCHEDULES',
  LOAD_URGENTNUMBERS = 'LOAD_URGENTNUMBERS',
  REFRESH_URGENTNUMBERS = 'REFRESH_URGENTNUMBERS',
  LOAD_SIGNALEMENTS = 'LOAD_SIGNALEMENTS',
  LOAD_SIGNALEMENT_ITEM = 'LOAD_SIGNALEMENT_ITEM',
  REFRESH_SIGNALEMENTS = 'REFRESH_SIGNALEMENTS',
  LOAD_CARTOGRAPHY_ZONES = 'LOAD_CARTOGRAPHY_ZONES',
  LOAD_CARTOGRAPHY_BEACON = 'LOAD_CARTOGRAPHY_BEACON',
  LOAD_CARTOGRAPHY_REAL_TIME = 'LOAD_CARTOGRAPHY_REAL_TIME',
  LOAD_CARTOGRAPHY_REAL_TIME_ITEMS = 'LOAD_CARTOGRAPHY_REAL_TIME_ITEMS',
  LOAD_CANTINE = 'LOAD_CANTINE',
  LOAD_MOVIE = 'LOAD_MOVIE',
  LOAD_MOVIE_LIST = 'LOAD_MOVIE_LIST',
  REFRESH_MOVIE_LIST = 'REFRESH_MOVIE_LIST',
  LOAD_CITYS = 'LOAD_CITYS',
  LOAD_CITYS_USER = 'LOAD_CITYS_USER',
  REFRESH_CITYS = 'REFRESH_CITYS',
  LOAD_AIR_QUALITY = 'LOAD_AIR_QUALITY',
  CHECK_AIR_QUALITY = 'CHECK_AIR_QUALITY',
  LOAD_GRID_AIR_QUALITY = 'LOAD_GRID_AIR_QUALITY',
  SAVING_NOTIFICATION_SETTINGS = 'SAVING_NOTIFICATION_SETTINGS',
  LOAD_WEATHER_DAYS = 'LOAD_WEATHER_DAYS',
  LOAD_EXTERNAL_LINKS = 'LOAD_EXTERNAL_LINKS',
  REFRESH_EXTERNAL_LINKS = 'REFRESH_EXTERNAL_LINKS',
  LOAD_TASKREPORT = 'LOAD_TASKREPORT',
  REFRESH_TASKREPORT = 'REFRESH_TASKREPORT',
  LOAD_TASKREPORT_ITEM = 'LOAD_TASKREPORT_ITEM',
  LOAD_IS_AUTHORIZED_GRC = 'LOAD_IS_AUTHORIZED_GRC',
  PUSH_FAVORITES_USER_IDS = 'PUSH_FAVORITES_USER_IDS',
  LOAD_FAVORITES_ITEM = 'LOAD_FAVORITES_ITEM',
  LOAD_TIDE_DETAILS = 'LOAD_TIDE_DETAILS',
  LOAD_GRID_TIDE = 'LOAD_GRID_TIDE',
  LOAD_SURVEYS = 'LOAD_SURVEYS',
  REFRESH_SURVEYS = 'REFRESH_SURVEYS',
}

export interface LoadingState {
  [name: string]: {
    isLoading: boolean
    status: {
      ok: boolean
      message?: string
    }
  }
}

export interface LoadingProps {
  isLoading?: boolean
}
