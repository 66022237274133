import { PagingResult } from '../types'

export type SurveysResult = PagingResult<SurveyItem>

export interface SurveyItem {
  uuid: string
  id: number
  title: string
  secure: boolean
  publication: SurveyItemPublication
  nbQuestions: number
  nbQuestionsAnswered: number
}

interface SurveyItemPublication {
  status: SurveyStatus
  enablePublish: boolean
  startDate: number
  endDate: number
  createdAt: number
  updatedAt: number
}

export enum SurveyStatus {
  DRAFT = 0,
  PUBLISHED = 1,
  NOT_PUBLISHED = 2,
  PLANNED = 3,
  EXPIRED = 4,
}
