import { MeResult } from '../../api/Me/types'

export type MeState = {
  me: MeResult
}

export enum MeActionTypes {
  LOAD_ME = 'LOAD_ME',
  STORE_ME = 'STORE_ME',
}
