import { ActionsOfType } from '@martin_hotell/rex-tils'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { ActionSurveysObjectTypes, SurveysActions } from './action'
import { SurveysActionTypes } from './types'
import { SurveysResult } from '../../api/Surveys/types'
import { surveysPagingSelector } from './selector'
import { SurveysApi } from '../../api/Surveys'
import { isOfflineError } from '../../services/errorManager'
import { addLoader } from '../loading/sagas'
import { LoaderName } from '../loading/types'

const PAGE_SIZE = 30

function* loadSurveysSaga(action: ActionsOfType<ActionSurveysObjectTypes, SurveysActionTypes.LOAD_SURVEYS>) {
  const paging: SurveysResult | null = yield select(surveysPagingSelector, action.payload.idPage.toString())
  let page = 0
  if (!action.payload.clear && paging && paging.page !== undefined) {
    page = paging.page + 1
  }

  try {
    const result: SurveysResult = yield call(SurveysApi.loadSurveys, page, PAGE_SIZE)
    if (action.payload.clear) {
      yield put(SurveysActions.storeClearSurveys(result, action.payload.idPage))
    } else {
      yield put(SurveysActions.storeSurveys(result, action.payload.idPage))
    }
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* refreshSurveysSaga(action: ActionsOfType<ActionSurveysObjectTypes, SurveysActionTypes.REFRESH_SURVEYS>) {
  yield call(loadSurveysSaga, SurveysActions.loadSurveys(true, action.payload.idPage))
}

export function* loadSurveysWatcher() {
  yield takeEvery(SurveysActionTypes.LOAD_SURVEYS, addLoader(loadSurveysSaga, LoaderName.LOAD_SURVEYS))
}

export function* refreshSurveysWatcher() {
  yield takeEvery(SurveysActionTypes.REFRESH_SURVEYS, addLoader(refreshSurveysSaga, LoaderName.REFRESH_SURVEYS))
}
