import { env } from '../../environment'
import { BaseApi } from '../BaseApi'

export class AuthenticationApi {
  public static async signUp(uuid: string) {
    return BaseApi.baseCall()
      .headers({
        clientuuid: env().clientUuid,
      })
      .url('api/auth/signup')
      .post({ client_id: uuid })
      .json(json => json)
  }

  public static async signIn(credentials: { uuid: string; password: string } | undefined) {
    return BaseApi.baseCall()
      .url('api/auth/signin')
      .post({ usernameOrEmail: credentials?.uuid || '', password: credentials?.password || '' })
      .json(json => json)
  }

  public static async signInUser(credentials: { uuid: string; password: string }) {
    return BaseApi.baseCall()
      .headers({
        clientuuid: env().clientUuid,
      })
      .url('api/auth/signin')
      .post({ usernameOrEmail: credentials.uuid, password: credentials.password })
      .error(202, error => error)
      .json(json => json)
  }

  public static signInWith2fa = ({ uuid, password, code }: { uuid: string; password: string; code: string }) =>
    BaseApi.baseCall()
      .headers({
        clientuuid: env().clientUuid,
      })
      .url(`api/auth/signin-check/${code}`)
      .post({
        usernameOrEmail: uuid,
        password,
      })
      .json(json => json)

  public static refreshToken = ({ refreshToken }: { refreshToken: string }) =>
    BaseApi.baseCall()
      .url('api/auth/refreshtoken')
      .post({
        refreshToken,
      })
      .json(json => json)

  public static resetPassword = ({ password, newPassword }: { password: string; newPassword: string }) =>
    BaseApi.authenticatedCall().url('v1/mobile/me/update-pwd').post({
      password,
      newPassword,
    })

  public static sendResetPassword = ({ token, password }: { token: string; password: string }) =>
    BaseApi.baseCall()
      .headers({
        clientuuid: env().clientUuid,
      })
      .url('api/auth/password-reset/verify')
      .post({
        token,
        newPassword: password,
      })
      .json(json => json)

  public static sendResetPasswordSendEmail = ({ email }: { email: string }) =>
    BaseApi.baseCall()
      .headers({
        clientuuid: env().clientUuid,
      })
      .url('api/auth/password-reset')
      .post({
        email,
      })
      .json(json => json)

  public static sendResetPasswordVerification = ({ token, code }: { token: string; code: string }) =>
    BaseApi.baseCall()
      .headers({
        clientuuid: env().clientUuid,
      })
      .url('api/auth/password-reset/verify-code')
      .post({
        token,
        code,
      })
      .json(json => json)
}
