import { call, put, takeEvery } from 'redux-saga/effects'
import { ActionsOfType } from '@martin_hotell/rex-tils'
import { ActionMeObjectTypes, MeActions } from './actions'
import { MeActionTypes } from './types'
import { MeApi } from '../../api/Me/MeApi'
import { isOfflineError } from '../../services/errorManager'

function* loadMe(_action: ActionsOfType<ActionMeObjectTypes, MeActionTypes.LOAD_ME>) {
  try {
    const me = yield call(MeApi.loadMe)
    yield put(MeActions.storeMe(me))
  } catch (error) {
    if (!isOfflineError(error as Error)) {
      console.error(error)
    }
  }
}

export function* loadMeWatcher() {
  yield takeEvery(MeActionTypes.LOAD_ME, loadMe)
}
