import { SurveysResult } from '../../api/Surveys/types'

export type SurveysState = {
  pages: {
    [key: string]: SurveysResult
  }
}

export enum SurveysActionTypes {
  LOAD_SURVEYS = 'LOAD_SURVEYS',
  STORE_SURVEYS = 'STORE_SURVEYS',
  REFRESH_SURVEYS = 'REFRESH_SURVEYS',
  STORE_CLEAR_SURVEYS = 'STORE_ClEAR_SURVEYS',
}
