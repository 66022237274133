import { DateTime, Duration, LocaleOptions } from 'luxon'
import { i18n } from '../../lib/i18n'
import { capitalize } from '../textUtil'

/**
 * Date avec un format particulier. exemple > Le 23/09/2019 à 14:30
 * @param item timestamp
 */
export const toDDMMYYYYatHHMM = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat(i18n.t('date.LeDDMMYYYatHHMM'))
}

/**
 * Date avec un format particulier. exemple > Le 23/09/2019
 * fr: dd/MM/YYYY, en: MM/dd/YYYY
 * @param item timestamp
 */
export const toDDMMYYYY = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat(i18n.t('date.LeDDMMYYY'))
}

/**
 * Date au format jour et mois en toutes lettres. ex > 24 Septembre
 * @param item timestamp
 */
export const toDDMMM = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat('dd MMM')
}

/**
 * Date au format HH:MM. exemple > '14:30'
 * @param item timestamp
 */
export const toHHMM = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat("HH':'mm")
}

export const fromDDMMYYYYtoDDMMYYYY = (from: number, to: number): string => {
  const fromDate = DateTime.fromMillis(from).setLocale(i18n.locale)
  const toDate = DateTime.fromMillis(to).setLocale(i18n.locale)
  const fromString = fromDate.toFormat(i18n.t('date.fromDDMMYYYY'))
  const toString = toDate.toFormat(i18n.t('date.toDDMMYYYY'))
  return `${capitalize(fromString)} ${toString}`
}

/**
 * Date simple de la journée. exemple > Mercredi 23 octobre
 * @param item timeStamp
 */
export const dateToHuge = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toLocaleString(DateTime.DATE_HUGE as LocaleOptions)
}

export const dateToHugeFromISO = (item: string): string => {
  const d = DateTime.fromISO(item).setLocale(i18n.locale)
  return d.toLocaleString(DateTime.DATE_HUGE as LocaleOptions)
}

/**
 * Date simple de la journée. exemple > Mercredi 23 octobre à 18h15
 * @param item timeStamp
 */
export const datetimeToHuge = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toLocaleString({
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  })
}

/**
 * Jour de la semaine en toute lettre
 * @param item timeStamp
 */
export const dateToDayOfWeek = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat('cccc')
}

/**
 * Jour du mois : Exemple '6' pour le 6 octobre
 * @param item timeStamp
 */
export const dateToDayOfMonth = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat('d')
}

/**
 * Exemple : Janv.
 * @param item timeStamp
 */
export const dateToMonthShort = (item: number): string => {
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat('LLL')
}

/**
 * Date au format Jour mois Année
 * @param item timeStamp
 */
export const dateToDayMonthYear = (item: number): string => {
  if (!item) {
    return ''
  }
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toFormat('DDD')
}

/**
 * Date au format Jour mois Année
 * @param item timeStamp
 */
export const dateToDayMonth = (item: number): string => {
  if (!item) {
    return ''
  }
  const d = DateTime.fromMillis(item).setLocale(i18n.locale)
  return d.toLocaleString({
    month: 'short',
    day: 'numeric',
  })
}

/**
 *
 * @param dayOfWeek Ordinal day of week
 */
export const dayOfWeekOrdinal = (dayOfWeek: number): string => {
  switch (dayOfWeek) {
    case 1:
      return i18n.t('days.sunday')
    case 2:
      return i18n.t('days.monday')
    case 3:
      return i18n.t('days.tuesday')
    case 4:
      return i18n.t('days.wednesday')
    case 5:
      return i18n.t('days.thursday')
    case 6:
      return i18n.t('days.friday')
    case 7:
      return i18n.t('days.saturday')
    default:
      return ''
  }
}

export const dateTimefromRFC2822ToHuge = (item: string): string => {
  if (!item) {
    return ''
  }
  const d = DateTime.fromRFC2822(item).setLocale(i18n.locale)
  return d.toLocaleString({
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  })
}

/**
 * Retourne le nombre de minutes depuis le timeStamp passé en paramètre et le moment où cette fonction est appelé
 * @param item timeStamp
 */
export const intervalLightFromNow = (item: number): Duration => {
  const date1 = DateTime.fromMillis(item).setLocale(i18n.locale)
  const date2 = DateTime.local().setLocale(i18n.locale)
  return date2.diff(date1, ['years', 'months', 'days', 'hours', 'minutes', 'seconds'])
}

export const intervalForHumans = (item: number, light?: boolean): string => {
  const duration = intervalLightFromNow(item)
  if (duration.years > 0) {
    return duration.toFormat(i18n.t('date.DurationYears'))
  }
  if (duration.months > 0) {
    return duration.toFormat(i18n.t('date.DurationMonths'))
  }
  if (duration.days > 0) {
    return duration.toFormat(i18n.t('date.DurationDays'))
  }
  if (duration.hours > 0) {
    return light ? duration.toFormat(i18n.t('date.DurationHH')) : duration.toFormat(i18n.t('date.DurationHHMM'))
  }
  if (duration.minutes > 0) {
    return light ? duration.toFormat(i18n.t('date.DurationMM')) : duration.toFormat(i18n.t('date.DurationMMSS'))
  }
  if (duration.seconds > 0 && duration.seconds > 20) {
    return duration.toFormat(i18n.t('date.DurationSS'))
  }
  return i18n.t('date.now')
}
