import { StackScreenProps } from '@react-navigation/stack'
import _ from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { Spinner, View } from 'native-base'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'
import Swiper from 'react-native-swiper'
import { FormElement, FormResponseResult, FormSubmit } from '../../api/Form/types'
import { SignalementApi } from '../../api/Signalement/SignalementApi'
import { SignalementConfigResult } from '../../api/Signalement/types'
import { DefaultFieldValue } from '../../components/Fields/types'
import { FormBase } from '../../components/Form'
import { ListEmpty } from '../../components/ListEmpty'
import { LeftButtonBack, Page } from '../../components/Page'
import { i18n } from '../../lib/i18n'
import { ActionViewParamsType } from '../../navigation/Routes'
import { addGDPRCheckbox, getFormDefaultValues, initDefaultValues, sanitizeFormikValues } from '../../services/form'
import { trackEvent } from '../../services/matomo/matomo.service'
import { showSuccess } from '../../services/toast'

const filterDefaultValues = (keys: string[], defaultValues: DefaultFieldValue) =>
  keys.reduce((newSecondPageDefaultValues: DefaultFieldValue, key: string) => {
    newSecondPageDefaultValues[key] = defaultValues[key]
    return newSecondPageDefaultValues
  }, {})

const addSubCategoryId = (subCategoryId: number | null, fields: FormElement[], pageValues: DefaultFieldValue) => {
  if (subCategoryId) {
    const categoryField = fields.find(field => field.type === 'field_souscategorie')
    if (categoryField) {
      return {
        ...pageValues,
        [categoryField.id]: subCategoryId,
      }
    }
  }
  return pageValues
}

const Signalement: FunctionComponent<StackScreenProps<ActionViewParamsType, 'Signalement'>> = ({ navigation, route }) => {
  const { model, titre: title } = route.params
  const subCategory = route.params.sousCategorie as string
  const subCategoryId: number | null = +subCategory || null
  const [signalement, setSignalement] = useState<SignalementConfigResult | null>(null)
  const [defaultValues, setDefaultValues] = useState<DefaultFieldValue | null>(null)
  const [firstPageDefaultValues, setFirstPageDefaultValues] = useState<DefaultFieldValue | null>(null)
  const [secondPageDefaultValues, setSecondPageDefaultValues] = useState<DefaultFieldValue | null>(null)
  const [hasSecondPage, setHasSecondPage] = useState<boolean>(false)
  const [hasError, setHasError] = useState(false)
  const [page1Values, setPage1Values] = useState<DefaultFieldValue>({})
  const [mapFieldIdTypes, setMapFieldIdTypes] = useState<Map<number, string | null>>(new Map())
  const swiperRef = useRef<Swiper>(null)

  const initializeSignalement = async () => {
    try {
      const signalementResult = await SignalementApi.loadSignalement(model)
      if (!signalementResult) {
        return
      }
      const isSecondPageEmpty = isEmpty(signalementResult.configPage2)
      if (isSecondPageEmpty) {
        signalementResult.configPage1 = addGDPRCheckbox(signalementResult.rgpdMessage, signalementResult.configPage1)
      } else {
        signalementResult.configPage2 = addGDPRCheckbox(signalementResult.rgpdMessage, signalementResult.configPage2)
      }
      setSignalement(signalementResult)
    } catch (error) {
      setHasError(true)
    }
  }
  const initializeDefaultValues = () => {
    if (!signalement) {
      return
    }

    setDefaultValues({
      ...initDefaultValues(signalement.configPage1),
      ...initDefaultValues(signalement.configPage2),
      ...getFormDefaultValues(signalement.defaultValues),
    })
  }

  const initializeHasSecondPage = () => {
    if (!signalement) {
      return
    }
    setHasSecondPage(!isEmpty(signalement.configPage2))
  }

  const initializeDefaultValuesForPages = () => {
    if (!defaultValues || !signalement) {
      return
    }

    const firstPageKeys = signalement.configPage1.map(field => `${field.id}`)
    let firstPageValues = filterDefaultValues(firstPageKeys, defaultValues)
    firstPageValues = addSubCategoryId(subCategoryId, signalement.configPage1, firstPageValues)
    setFirstPageDefaultValues(firstPageValues)

    if (hasSecondPage) {
      const secondPageKeys = signalement.configPage2.map(field => `${field.id}`)
      let secondPageValues = filterDefaultValues(secondPageKeys, defaultValues)
      secondPageValues = addSubCategoryId(subCategoryId, signalement.configPage2, secondPageValues)
      setSecondPageDefaultValues(secondPageValues)
    }
  }

  const initializeMapFieldTypeById = () => {
    if (!signalement) {
      return
    }
    const map = new Map<number, string | null>()
    signalement.configPage1.forEach(field => map.set(field.id, field.type))
    signalement.configPage2.forEach(field => map.set(field.id, field.type))
    setMapFieldIdTypes(map)
  }

  useEffect(() => {
    initializeSignalement()
  }, [])
  useEffect(initializeDefaultValues, [signalement])
  useEffect(initializeHasSecondPage, [signalement])
  useEffect(initializeMapFieldTypeById, [signalement])
  useEffect(initializeDefaultValuesForPages, [defaultValues])

  if (hasError) {
    return (
      <Page left={<LeftButtonBack />} title={title}>
        <ListEmpty libelle={i18n.t('form.noForm')} />
      </Page>
    )
  }

  if (!signalement || !defaultValues) {
    return (
      <Page left={<LeftButtonBack />} title={title}>
        <Spinner style={styles.container} />
      </Page>
    )
  }

  const page1Fields = signalement.configPage1
  const page2Fields = signalement.configPage2

  const buttonText = hasSecondPage ? i18n.t('signalement.next') : i18n.t('signalement.send')

  const toFirstPage = () => {
    if (swiperRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(swiperRef.current as any).scrollBy(-1)
    }
  }

  const trimEmail = (pageFields: FormElement[], values: DefaultFieldValue) => {
    if (pageFields && pageFields.length > 0) {
      _.map(pageFields, element => {
        if (element.type === 'field_email') {
          const mail = values[element.id] as string
          _.set(values, element.id, _.trim(mail))
        }
      })
    }
    return values
  }

  const submitFirstPage = async (values: DefaultFieldValue) => {
    trimEmail(page1Fields, values)
    if (hasSecondPage && swiperRef.current) {
      setPage1Values(values)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(swiperRef.current as any).scrollBy(1)
      return new Map()
    }

    return submitForm(values)
  }

  const submitSecondPage = async (values: DefaultFieldValue) => {
    trimEmail(page2Fields, values)
    return submitForm(page1Values, values)
  }

  const handleFormResponse = (response: FormResponseResult): Map<number, string> => {
    const errors = new Map<number, string>()
    if (response.success) {
      navigation.popToTop()
      showSuccess(i18n.t('form.success'))
      return new Map()
    }
    response.errors.forEach(error => errors.set(error.id, error.message))

    return errors
  }

  const submitForm = async (firstPageValues: DefaultFieldValue, secondPageValues?: DefaultFieldValue) => {
    const values = {
      ...firstPageValues,
      ...(secondPageValues || {}),
    }

    try {
      const form: FormSubmit = {
        reponses: sanitizeFormikValues(values, mapFieldIdTypes),
        rgpd: true,
      }
      const response = await SignalementApi.submitSignalement(form, model)
      trackEvent({
        category: 'Signalement',
        actionName: 'Signalement',
        eventName: `Appui sur le bouton envoyer`,
        value: 'Envoi du signalement',
      })
      return handleFormResponse(response)
    } catch (error) {
      console.error(error)
    }
    return new Map()
  }

  return (
    <Page left={<LeftButtonBack />} title={title}>
      <Swiper ref={swiperRef} scrollEnabled={false} loop={false} showsPagination={false}>
        <View style={styles.container}>
          {firstPageDefaultValues !== null && <FormBase fields={page1Fields} buttonText={buttonText} submit={submitFirstPage} defaultValues={firstPageDefaultValues} />}
        </View>
        {hasSecondPage && secondPageDefaultValues !== null && (
          <View style={styles.container}>
            <FormBase
              fields={page2Fields}
              extraFields={page1Fields}
              buttonText={i18n.t('signalement.send')}
              onBackPress={toFirstPage}
              submit={submitSecondPage}
              defaultValues={secondPageDefaultValues}
            />
          </View>
        )}
      </Swiper>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export { Signalement }
